.intro_slider_overlay {
  display: none;
}

.intro_overlay {
    background: var(--black);
    @media(max-width:991px){
      width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        position: fixed;
    }
  // width: 100%;
  // height: 100%;
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // z-index: -1;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // -webkit-box-pack: center;
  // -ms-flex-pack: center;
  // justify-content: center;
  // background: #000;

  // &::before {
  //     content: "";
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0px;
  //     left: 0px;
  //     z-index: 1;
  //     background: rgba(0, 0, 0, 0.1);
  // }

  @media (min-width: 992px) {
    video {
      position: fixed !important;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.offer-sicker-container {
  position: absolute;
  top: var(--spacing-xxl-11);
  right: var(--spacing-xxl-11);
  color: #fff;

  transform: rotate(45deg);

  .heading {
    font-size: var(--text-4xl) !important;
    white-space: pre-line !important;
  }

  .description {
    text-decoration: underline;
  }
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .offer-sicker-container {
    position: fixed;
    top: 0;
    right: 0;

    .description {
      font-size: var(--text-xs) !important;
    }

    .heading {
      font-size: var(--text-l) !important;
      line-height: 1 !important;
    }
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .offer-sicker-container {
    position: fixed;
    top: var(--spacing-xs);
    right: var(--spacing-xxl-2);

    .description {
      font-size: var(--text-xs) !important;
    }

    .heading {
      font-size: var(--text-xl) !important;
      line-height: 1 !important;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .offer-sicker-container {
    position: fixed;
    top: var(--spacing-xs);
    right: var(--spacing-xxl-2);

    .description {
      font-size: var(--text-xs) !important;
    }

    .heading {
      font-size: var(--text-xl) !important;
      line-height: 1 !important;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .offer-sicker-container {
    position: fixed;
    top: var(--spacing-xs);
    right: var(--spacing-xxl-2);

    .description {
      font-size: var(--text-sm) !important;
    }

    .heading {
      font-size: var(--text-2xl) !important;
      line-height: 1 !important;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .offer-sicker-container {
    position: fixed;
    top: var(--spacing-xs);
    right: var(--spacing-xxl-2);

    .description {
      font-size: var(--text-base) !important;
    }

    .heading {
      font-size: var(--text-2xl) !important;
      line-height: 1 !important;
    }
  }
}

.intro_btn {
  color: #fff;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  left: 0;
  z-index: 80;
  padding-right: 20px;

  a,
  a:hover {
    display: inline-flex;
    padding: 10px 35px;
    background: #181818;
    margin-left: 0.5rem;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 1rem;
    color: #fff;
  }
}

.intro_slider_inner_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  max-width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  background: var(--black);
}

.is_innerItem {
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.is_i_txt_cb {
  background: rgba(0, 0, 0, 0.7);
  padding: 15px 15px;
  display: inline-block;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);

  p {
    font-size: 1.6rem;
    font-family: var(--bs-font-main-title-family);
    color: #fff;
    line-height: 1;
    margin: 0;
    text-align: left;
    margin: 0px;
    padding: 20px 15px;

    span {
      font-family: var(--bs-font-family);
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}

@media (max-width: 991px) {
  #intro-video {
    height: auto;
    position: relative;
  }

  .intro_btn {
    justify-content: center;
    padding-right: 0px;

    a {
      padding: 10px 25px;
    }
  }
}

@media (max-width: 767px) {
  .intro_slider_inner_container {
    height: 100%;

    /* .slick-slider,
        .slick-list {
            height: 100%;
            position: relative;
        }

        .slick-track {
            display: flex !important;
            height: 100%;
        }

        .slick-slide {
            height: inherit !important;
        }

        .slick-slide>div {
            height: 100%;
            position: relative;
        }
        */

    .is_o_cb {
      height: 100%;
      position: relative;
    }
  }

  .is_innerItem {
    // height: 100%;
    background-size: contain;
    top: 0px;
  }

  .is_i_txt_cb {
    display: block;
    width: 100%;
    position: absolute;
    top: auto;
    bottom: 50px;
    right: 0px;
    text-align: center;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);

    p {
      font-size: 1rem;
      margin: 0px;
      padding: 10px 0px;
      text-align: center;

      span {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 424px) {
  .is_i_txt_cb {
    p {
      font-size: 0.778rem;
      padding: 10px 0px 5px;

      span {
        font-size: 0.778rem;
      }
    }
  }
}
